import './RecruitmentPreSignLandingPage.less'

import { Button } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { UserContext } from '../..'
import { useScopedIntl } from '../../../../hooks'
import {
  AccountType,
  CustomisationData,
  RecruitmentStudySettings,
  fetchCustomisationData as fetchCustomisationDataRequest,
  fetchRecruitmentStudySettings
} from '../../../../requests'
import { routes } from '../../../../routes'
import { proxyMinioToLocalhost } from '../../../../utils'
import { DatacBox, DatacIcon, DatacIconName, DatacLoading, DatacMessage, DatacTitle } from '../../../common'
import { EditorOutput } from '../../../shared/Editor'
import { StyleCustomisation } from '../../Layout/StyleCustomisation'

interface LandingPageDetailProps {
  icon: DatacIconName
  title: string
  value: string | number
}

const LandingPageDetail: React.FC<LandingPageDetailProps> = ({ icon, title, value }) => {
  return (
    <div className="recruitment-pre-sign-landing-page__detail">
      <DatacIcon name={icon} type="transparent" />
      <DatacTitle size="small">{title}</DatacTitle>
      <div className="recruitment-pre-sign-landing-page__detail__value">{value}</div>
    </div>
  )
}

interface RecruitmentPreSignLandingPageProps {
  qrCode: string
  onApply: () => void
}

export const RecruitmentPreSignLandingPage: React.FC<RecruitmentPreSignLandingPageProps> = ({ qrCode, onApply }) => {
  const intl = useScopedIntl('')
  const intlPreSign = useScopedIntl('recruitment.pre_sign')
  const initDuration = useScopedIntl('recruitment.study.fields.duration_unit')
  const [isFetchingSettings, setIsFetchingSettings] = useState(true)
  const [customisationData, setCustomisationData] = useState<CustomisationData>(null)
  const [isLoadingCustomisation, setIsLoadingCustomisation] = useState(false)
  const [studySettings, setStudySettings] = useState<RecruitmentStudySettings>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const { user } = useContext(UserContext)

  useEffect(() => {
    fetchRecruitmentStudySettings(
      { qrCode },
      {
        onSuccess: settings => {
          setIsFetchingSettings(false)
          setStudySettings(settings)
        },
        onRequestError: code => {
          setIsFetchingSettings(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
    setIsLoadingCustomisation(true)
    fetchCustomisationDataRequest(true, {
      onSuccess: (data: CustomisationData) => {
        setCustomisationData(data)
        setIsLoadingCustomisation(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsLoadingCustomisation(false)
      }
    })
  }, [])

  const onMore = () => detailsRef.current.scrollIntoView({ behavior: 'smooth' })

  return (
    <div className="recruitment-pre-sign-landing-page">
      {customisationData && (
        <StyleCustomisation color={customisationData.mainColor} buttonColor={customisationData.buttonColor} />
      )}
      <DatacLoading isLoading={isFetchingSettings || isLoadingCustomisation}>
        <div className="recruitment-pre-sign-landing-page__login">
          <Button
            type="default"
            onClick={() => navigate(user.getSubjectId() ? routes.signIn(AccountType.Subject) : routes.subjectDashboard)}
          >
            {intlPreSign(user.getSubjectId() ? 'my_dashboard' : 'login')}
          </Button>
        </div>
        <div className="recruitment-pre-sign-landing-page__header">
          <div className="recruitment-pre-sign-landing-page__header__left">
            <DatacTitle type="h1" className="recruitment-pre-sign-landing-page__title">
              {studySettings?.displayName}
            </DatacTitle>
            {studySettings?.summary}

            <div className="recruitment-pre-sign-landing-page__header__left__buttons">
              {studySettings?.active && (
                <Button onClick={onApply} type="primary">
                  {intlPreSign('apply')}
                  <DatacIcon name="arrowRight" type="white" raw />
                </Button>
              )}
              <Button onClick={onMore} type="default">
                {intlPreSign('more')}
              </Button>
            </div>
          </div>
          {!!studySettings?.imageUrl && (
            <div className="recruitment-pre-sign-landing-page__header__right">
              <img src={proxyMinioToLocalhost(studySettings?.imageUrl)} alt={studySettings?.displayName} />
            </div>
          )}
        </div>
        <div className="recruitment-pre-sign-landing-page__content" ref={detailsRef}>
          <div className="recruitment-pre-sign-landing-page__content__left">
            {studySettings?.details && (
              <DatacBox>
                <DatacTitle size="small">{intlPreSign('about')}</DatacTitle>
                <EditorOutput content={studySettings.details} />
              </DatacBox>
            )}
          </div>
          <div className="recruitment-pre-sign-landing-page__content__right">
            <DatacBox>
              <DatacTitle size="small">{intlPreSign('participation_details')}</DatacTitle>
              {studySettings?.age != null && (
                <LandingPageDetail icon="users" title={intlPreSign('age')} value={studySettings.age} />
              )}
              {!!studySettings?.sex && (
                <LandingPageDetail icon="gender" title={intlPreSign('sex')} value={studySettings.sex} />
              )}
              {studySettings?.visitNumber != null && (
                <LandingPageDetail
                  icon="checkCircle"
                  title={intlPreSign('visit_number')}
                  value={studySettings.visitNumber}
                />
              )}
              {studySettings?.duration != null && (
                <LandingPageDetail
                  icon="calendar"
                  title={intlPreSign('duration')}
                  value={`${studySettings.duration} ${initDuration(studySettings.durationUnit.toLowerCase())}`}
                />
              )}
              {!!studySettings?.centerName && (
                <LandingPageDetail icon="mapPin" title={intlPreSign('location')} value={studySettings.centerName} />
              )}
              {!!studySettings?.active && (
                <Button onClick={onApply} type="primary">
                  {intlPreSign('apply')}
                  <DatacIcon name="arrowRight" type="white" raw />
                </Button>
              )}
            </DatacBox>
          </div>
        </div>
      </DatacLoading>
    </div>
  )
}
