import './RecruitmentPreSignForm.less'

import { Form, Input } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { AccountType, checkSubjectAccount } from '../../../../requests'
import { routes } from '../../../../routes'
import { DatacMessage, DatacSubtitle, DatacTitle } from '../../../common'
import { FormInputWrapper } from '../../FormInputWrapper'
import { FormSubmitButton } from '../../FormSubmitButton'
import { Layout } from '../../Layout'

interface RecruitmentPreSignFormProps {
  qrCode: string
}

export const RecruitmentPreSignForm: React.FC<RecruitmentPreSignFormProps> = ({ qrCode }) => {
  const intl = useScopedIntl('')
  const intlPreSign = useScopedIntl('recruitment.pre_sign')
  const intlField = useScopedIntl('auth.signin.field')
  const [isSubmitOngoing, setIsSubmitOngoing] = useState(false)
  const [formInstance] = Form.useForm()

  const onFormSubmit = ({ email }: { email: string }) => {
    setIsSubmitOngoing(true)
    checkSubjectAccount(
      { email },
      {
        onSuccess: exists => navigate((exists ? routes.recruitmentSurvey : routes.recruitmentSignup)(qrCode, email)),
        onNotFound: () => navigate(routes.recruitmentSignup(qrCode, email)),
        onRequestError: code => {
          setIsSubmitOngoing(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )
  }

  return (
    <Layout accountType={AccountType.Subject} isWider>
      <DatacTitle type="h1" className="recruitment-pre-sign-form__title">
        {intlPreSign('title')}
      </DatacTitle>
      <DatacSubtitle type="h2" className="recruitment-pre-sign-form__subtitle">
        {intlPreSign('subtitle')}
      </DatacSubtitle>

      <Form form={formInstance} name="preSignForm" onFinish={onFormSubmit} className="recruitment-pre-sign-form">
        <FormInputWrapper label={intlField('email.label')}>
          <Form.Item
            name="email"
            validateFirst
            rules={[
              {
                required: true,
                whitespace: true,
                message: intlField('email.validation.required')
              },
              {
                type: 'email',
                message: intlField('email.validation.email')
              }
            ]}
          >
            <Input className="auth-form-input" size="large" placeholder={intlField('email.placeholder')} />
          </Form.Item>
        </FormInputWrapper>

        <Form.Item>
          <FormSubmitButton label={intlPreSign('continue')} loading={isSubmitOngoing} />
        </Form.Item>
      </Form>
    </Layout>
  )
}
