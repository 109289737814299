import React from 'react'

import { Layout } from '../../components/auth/Layout'
import { SignUpContent } from '../../components/auth/SignUpContent'
import { AccountType } from '../../requests'

const SignUpPage = () => {
  return (
    <Layout accountType={AccountType.User}>
      <SignUpContent accountType={AccountType.User} />
    </Layout>
  )
}

export default SignUpPage
export const Head = () => <title>Datacapt - Sign Up</title>
