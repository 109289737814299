import './SignUpContent.less'

import { Link, navigate } from 'gatsby-plugin-react-intl'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AccountType,
  CustomConsent,
  LoginType,
  fetchCustomConsents,
  fetchTenantData,
  registerNewAccount,
  verifyRegistrationToken
} from '../../../requests'
import { routes } from '../../../routes'
import { getLanguage, getRecruitmentCodeAndEmailFromHash } from '../../../utils'
import { DatacMessage, DatacSubtitle, DatacTitle } from '../../common'
import { RecruitmentPreSignSwitch } from '../RecruitmentPreSign'
import { SignUpForm, SignUpFormData } from '../SignUpForm'
import { PasswordRejectionError } from '../validatePassword'

export enum EmailRejectionError {
  Wrong = 'wrong',
  Taken = 'taken'
}

interface SignUpContentProps {
  accountType: AccountType
  isQrUsed?: boolean
  isRecruitment?: boolean
}

export const SignUpContent: React.FC<SignUpContentProps> = ({ accountType, isQrUsed, isRecruitment }) => {
  const intl = useScopedIntl('')
  const intlSignUp = useScopedIntl('auth.signup')
  const intlRecruitment = useScopedIntl('recruitment.signup')
  const [token, urlEmail] = getRecruitmentCodeAndEmailFromHash()
  const [tokenVerified, setTokenVerified] = useState(false)
  const [passwordRejectionError, setPasswordRejectionError] = useState<PasswordRejectionError>(null)
  const [emailRejectionError, setEmailRejectionError] = useState<EmailRejectionError>(null)
  const [email, setEmail] = useState<string>(null)
  const [loginType, setLoginType] = useState<LoginType>()
  const [minPasswordLength, setMinPasswordLength] = useState<number>(null)
  const [consents, setConsents] = useState<CustomConsent[]>(null)
  const [isFetchingConsents, setIsFetchingConsents] = useState(true)

  useEffect(() => {
    fetchTenantData({
      onSuccess: ({ minPasswordLength }) => setMinPasswordLength(minPasswordLength),
      onRequestError: () => DatacMessage.error(intlSignUp('request_error.title'), intlSignUp('request_error.subtitle'))
    })

    if (accountType === AccountType.Subject) {
      fetchCustomConsents(
        { language: getLanguage() },
        {
          onSuccess: consents => {
            setConsents(consents)
            setIsFetchingConsents(false)
          },
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
    }

    if (isQrUsed) {
      setLoginType(LoginType.Password)
      setTokenVerified(true)
      return
    }

    verifyRegistrationToken(
      { token, accountType },
      {
        onSuccess: ({ email, loginType }) => {
          setEmail(email || urlEmail)
          setLoginType(loginType || LoginType.Password)
          setTokenVerified(true)
        },
        onRequestError: () => navigate(routes.signUpFail(accountType))
      }
    )
  }, [])

  const onFormSubmit = (data: SignUpFormData) =>
    new Promise<void>(resolve =>
      registerNewAccount(
        { ...data, token, accountType, isQrUsed },
        {
          onSuccess: invitationToken => {
            navigate(routes.signUpSuccess(accountType, invitationToken || token), { state: { email: data.email } })
            resolve()
          },
          onRequestError: () => {
            DatacMessage.error(intlSignUp('request_error.title'), intlSignUp('request_error.subtitle'))
            resolve()
          },
          onNoSuchInvitation: () => {
            navigate(routes.signUpFail(accountType))
            resolve()
          },
          onWrongEmail: () => {
            setEmailRejectionError(EmailRejectionError.Wrong)
            resolve()
          },
          onEmailTaken: () => {
            setEmailRejectionError(EmailRejectionError.Taken)
            resolve()
          },
          onPasswordTooCommon: () => {
            setPasswordRejectionError(PasswordRejectionError.TooCommon)
            resolve()
          },
          onPasswordTooSimilar: () => {
            setPasswordRejectionError(PasswordRejectionError.TooSimilar)
            resolve()
          }
        }
      )
    )

  const onEmailChange = () => emailRejectionError && setEmailRejectionError(null)
  const onPasswordChange = () => passwordRejectionError && setPasswordRejectionError(null)

  const isReady = tokenVerified && !!minPasswordLength && (!isFetchingConsents || accountType === AccountType.User)

  return isReady ? (
    <div className="signup-content">
      <DatacTitle type="h1" className="signup-content__title">
        {isRecruitment ? intlRecruitment('title') : intlSignUp('title')}
      </DatacTitle>
      <DatacSubtitle type="h2" className="signup-content__subtitle">
        {isRecruitment ? intlRecruitment('subtitle') : intlSignUp(`subtitle.${accountType}`)}
      </DatacSubtitle>

      {isRecruitment && <RecruitmentPreSignSwitch qrCode={token} email={email} selected="signup" />}

      <SignUpForm
        onSubmit={onFormSubmit}
        passwordRejectionError={passwordRejectionError}
        onEmailChange={onEmailChange}
        onPasswordChange={onPasswordChange}
        emailRejectionError={emailRejectionError}
        accountType={accountType}
        email={email || urlEmail}
        type={loginType}
        minPasswordLength={minPasswordLength}
        isQrUsed={isQrUsed}
        consents={consents}
      />
      <div className="signup-content__account-exists-message">
        {intlSignUp('account_exists')}
        <Link className="signup-content__sign-in-link" to={routes.signIn(accountType)}>
          {intlSignUp('sign_in')}
        </Link>
      </div>
    </div>
  ) : null
}
