import React, { useState } from 'react'

import { AccountType } from '../../../requests'
import { getRecruitmentCodeAndEmailFromHash } from '../../../utils'
import { Layout } from '../Layout'
import { SignUpContent } from '../SignUpContent'
import { RecruitmentPreSignForm } from './RecruitmentPreSignForm'
import { RecruitmentPreSignLandingPage } from './RecruitmentPreSignLandingPage'

export const RecruitmentPreSign: React.FC = () => {
  const [isShowingForm, setIsShowingForm] = useState(false)
  const [qrCode, email] = getRecruitmentCodeAndEmailFromHash()

  if (email)
    return (
      <Layout accountType={AccountType.Subject} isWider>
        <SignUpContent accountType={AccountType.Subject} isQrUsed isRecruitment />
      </Layout>
    )

  return isShowingForm ? (
    <RecruitmentPreSignForm qrCode={qrCode} />
  ) : (
    <RecruitmentPreSignLandingPage qrCode={qrCode} onApply={() => setIsShowingForm(true)} />
  )
}
