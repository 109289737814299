import './RecruitmentPreSignSwitch.less'

import classNames from 'classnames'
import { navigate } from 'gatsby-plugin-react-intl'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { routes } from '../../../../routes'

interface RecruitmentPreSignSwitchProps {
  qrCode: string
  email: string
  selected: 'signin' | 'signup'
}

export const RecruitmentPreSignSwitch: React.VFC<RecruitmentPreSignSwitchProps> = ({ qrCode, email, selected }) => {
  const intlSignUp = useScopedIntl('auth.signup')

  return (
    <div className="recruitment-pre-sign-switch">
      <div
        className={classNames(
          'recruitment-pre-sign-switch__button',
          selected === 'signin' && 'recruitment-pre-sign-switch__button__selected'
        )}
      >
        {intlSignUp('sign_in')}
      </div>
      <div
        onClick={() => (selected === 'signup' ? null : navigate(routes.recruitmentSignup(qrCode, email)))}
        className={classNames(
          'recruitment-pre-sign-switch__button',
          selected === 'signup'
            ? 'recruitment-pre-sign-switch__button__selected'
            : 'recruitment-pre-sign-switch__button__clickable'
        )}
      >
        {intlSignUp('submit')}
      </div>
    </div>
  )
}
